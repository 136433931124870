import $ from 'jquery';
import './bootstrap.js';
import 'jquery-validation'


let artworks = [];
let toggleCoupon;
let exportArtworkCsv;
let exportUsersCsv;
let setDateRange;
let sumPercent;
const updatePlaylistOrder = '/cms/playlists/update-order';


$(document).ready(() => {
    let date = new Date();

    if($("#i_from").val() != undefined && $("#i_to").val() != undefined) {
        $("#i_from").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            maxDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        });
        $("#i_from")
            .datepicker()
            .attr("type", "text");
        $("#i_to").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            maxDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        });
        $("#i_to")
            .datepicker()
            .attr("type", "text");
    }

    // Datepicker for joined from
    if($("#i_from_joined").val() != undefined && $("#i_to_joined").val() != undefined) {
        $("#i_from_joined").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            maxDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        });
        $("#i_from_joined")
            .datepicker()
            .attr("type", "text");
        $("#i_to_joined").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            maxDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        });
        $("#i_to_joined")
            .datepicker()
            .attr("type", "text");
    }

    if($("#i_from_expiring").val() != undefined && $("#i_to_expiring").val() != undefined) {
        // Datepicker for subscription expiration
        $("#i_from_expiring").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            // maxDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        });
        $("#i_from_expiring")
            .datepicker()
            .attr("type", "text");
        $("#i_to_expiring").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            // maxDate: new Date(date.getFullYear(), date.getMonth(), date.getDate())
        });
        $("#i_to_expiring")
            .datepicker()
            .attr("type", "text");
    }

    $("#currency").on("change", function() {
        if ($(this).val() === "usd") {
            $("#currency-unity").html("&dollar;");
        } else if ($(this).val() === "eur") {
            $("#currency-unity").html("&euro;");
        } else {
            $("#currency-unity").html("&pound;");
        }
    });
    $("#percent-off").on("keyup", function() {
        $(this).val(
            $(this)
                .val()
                .replace(/[^\d]/, "")
        );
        if ($(this).val() > 100) {
            $(this).val(100);
        }
    });
    $("#client-stats-btn").on("click", redirectToStats);
    $("#brand-stats-btn").on("click", redirectToBrandStats);
    $(".btn-search").on("click", searchArtworks);
    if ($("#redeem-by").length) {
        // Datepicker
        let date = new Date();
        $("#redeem-by").datepicker({
            uiLibrary: "bootstrap4",
            format: "dd-mm-yyyy",
            weekStartDay: 1,
            minDate: new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            )
        });
        $("#redeem-by")
            .datepicker()
            .attr("type", "text");
    }
    /**
     * Drag and drop artworks upload
     */
    $("#drop-file-zone").on("drop", e => {
        e.preventDefault();
        if (!e.dataTransfer) {
            e.dataTransfer = e.originalEvent.dataTransfer;
        }
        readImageFiles(e.dataTransfer.files);
    });
    $("#drop-file-zone").on("dragover", () => {
        return false;
    });
    $("#client-upload-btn").on("click", fileExplorer);
    $("#upload-artworks-btn").on("click", uploadArtworks);
    /** End of drag and drop */

    $('.limits a').on('click', function(event) {
        event.preventDefault();
        const href = $(this).attr('href');
        const urlParams = new URLSearchParams(href.split('?')[1]);
        const limit = urlParams.get('limit');
        const layout = urlParams.get('layout');

        const url = new URL(window.location.href);
        url.searchParams.set('limit', limit);
        if (layout) {
            url.searchParams.set('layout', layout);
        }
        window.location.href = url.toString();
    });

    $("#upload-csv-form").on("submit", e => {
        e.preventDefault();
        if ($("#brand").val() === "" && $("#client").val() === "") {
            $(".csv-error")
                .removeClass("d-none")
                .html("Brand or client missing.");
        } else if ($("#csv").val() === "" || $("#zip").val() === "") {
            $(".csv-error")
                .removeClass("d-none")
                .html("Csv or Zip missing.");
        } else {
            $(".csv-error")
                .addClass("d-none")
                .html("");
            $("#upload-csv-btn").html('<i class="fas fa-spin fa-spinner"></i>');
            $("#upload-csv-btn").attr("disabled", true);
            window.axios
                .post(
                    $("#upload-csv-form").attr("action"),
                    new FormData($("#upload-csv-form")[0]),
                    {
                        headers: {
                            "content-type": "multipart/form-data"
                        },
                        onUploadProgress: e => {
                            let percentage = (e.loaded * 100) / e.total;
                            if (percentage > 95) {
                                $("#progress").attr("aria-valuenow", "100");
                                $("#progress").css("width", "100%");
                                $("#progress").html(
                                    "File uploaded. Unzipping in S3, this might take a while."
                                );
                                $("#upload-csv-btn").html(
                                    'Unzipping <i class="fas fa-spin fa-spinner"></i>'
                                );
                            } else {
                                $("#progress").attr(
                                    "aria-valuenow",
                                    parseInt(percentage)
                                );
                                $("#progress").css(
                                    "width",
                                    parseInt(percentage) + "%"
                                );
                                $("#progress").html(parseInt(percentage) + "%");
                            }
                        }
                    }
                )
                .then(response => {
                    if (response && response.data.response === "Ok") {
                        window.location.href = response.data.data;
                    } else {
                        $(".csv-error")
                            .removeClass("d-none")
                            .html(response.data.message);
                    }
                    $("#upload-csv-btn").html("Upload");
                    $("#upload-csv-btn").attr("disabled", false);
                });
        }
    });

    $(".upload-form-selects select").on("change", e => {
        if (e.target.id === "brand") {
            $("#client").val("");
        } else {
            $("#brand").val("");
        }
    });

    /**
     * This executes add tag in create artwork
     */
    $(".btn-art_styles").on("click", () => {
        window.addElement("art_style");
    });
    $(".btn-themes").on("click", () => {
        window.addElement("theme");
    });
    $(".btn-colours").on("click", () => {
        window.addElement("colour");
    });
    $(".btn-moods").on("click", () => {
        window.addElement("mood");
    });
    $(".btn-video_arts").on("click", () => {
        window.addElement("video_art");
    });
    /** End of window.addElement */

    $(".confirmation").on("click", () => {
        return confirm("Are you sure?");
    });
    $(".checkbox-grid-main").on("click", toggleGridCheckboxes);
    $(".grid-image-wrapper img").on("click", toggleGridCheckbox);

    // Toggle artworks, pre artworks & playlists table checkboxes
    $(".checkbox-main").on("click", toggleCheckboxes);
    $(".checkbox-list").on("click", checkCheckboxes);

    $("#type").on("change", toggleVideoArts);
    $(".generic-switch").on("change", window.toggleField);
    $(".coupon-switch").on("change", toggleCoupon);
    $(".image-file-input").on("change", readImg);
    $(".custom-file-input").on("change", readFileName);
    $(".tag-item i").on("click", window.removeTag);

    $(".client-ajax").on("change", getClientArtists);

    $(".remove-artwork").on("click", removePlaylistArtwork);
    $(".btn-delete-artwork").on("click", deleteArtworks);
    $(".btn-delete-user").on("click", deleteUsers);
    $(".btn-delete-pre-artwork").on("click", deletePreArtworks);

    //Section: Playlists
    $(".btn-playlist-custom").on("click", copyPlaylistsToCustom);
    $(".btn-delete-playlist").on("click", deletePlaylists);
    $(".btn-update-playlists").on("click", validateUpdatePlaylistForm);
    $(".btn-delete-image-playlist").on("click", deleteImagePlaylistForm);
    $("#before-update-playlist").submit(() => {
        if ($("#image-preview").attr("src")) {
            $('input[name="set-image"]').attr("value", 1);
        }
        return true;
    });
    //End section: Playlists

    $(".copy-text").on("click", setClipboard);

    $(".preferences-box .img-box").on("click", togglePreferenceCheckbox);
    $(".form-check-radio").on("change", checkIsChecked);

    /**
     * When editing it adds the artwork tags to the form
     */
    if (typeof artworkMoods !== "undefined" && artworkMoods !== "")
        window.addArray(artworkMoods, "mood");
    if (typeof artworkColours !== "undefined" && artworkColours !== "")
        window.addArray(artworkColours, "colour");
    if (typeof artworkVideoArts !== "undefined" && artworkVideoArts !== "")
        window.addArray(artworkVideoArts, "video_art");
    if (typeof artworkThemes !== "undefined" && artworkThemes !== "")
        window.addArray(artworkThemes, "theme");
    if (typeof artworkArtStyles !== "undefined" && artworkArtStyles !== "")
        window.addArray(artworkArtStyles, "art_style");
    /** End of artwork editing */

    $("#publish-form").on("submit", e => {
        e.preventDefault();
        window
            .ajaxPostCall(
                $("#publish-form").attr("action"),
                new FormData($("#publish-form")[0])
            )
            .then(res => {
                if (res.message && res.message === "Error") {
                    //console.error(res.data);
                } else {
                    window.location.href = APP_URL + `/cms/artworks${res.data}`;
                }
            });
    });

    $(".choose-upload-type .form-check-input").on(
        "click",
        showUploadArtworkForm
    );

    $(".btn-publish").on("click", validateForm);
    $(".btn-update-artworks").on("click", validateUpdateArtworkForm);
    $(".btn-add-playlist").on("click", validatePlaylistForm);

    /**
     * Arworks onload listeners:
     */

    //add listener to button search
    $("#btn_collapse_searchbox").on("click", function() {
        // Declare the variable `val` before using it
        var hiddenField = $("#hidden_searchbox_collapsed");
        let val = hiddenField.val();
        hiddenField.val(val === "collapsed" ? "non-collapsed" : "collapsed");

        // Toggle caret
        if ($("#collapse_caret").hasClass("fas fa-caret-down")) {
            $("#collapse_caret").removeClass("fas fa-caret-down");
            $("#collapse_caret").addClass("fas fa-caret-up");
        } else {
            $("#collapse_caret").removeClass("fas fa-caret-up");
            $("#collapse_caret").addClass("fas fa-caret-down");
        }
    });

        //add listener to export csv filtering button
        $("#btn_collapse_csv_filtering").on("click", function() {
            if ($("#collapse_caret_csv_filtering").hasClass("fas fa-caret-down")) {
                $("#collapse_caret_csv_filtering").removeClass("fas fa-caret-down");
                $("#collapse_caret_csv_filtering").addClass("fas fa-caret-up");
            } else {
                $("#collapse_caret_csv_filtering").removeClass("fas fa-caret-up");
                $("#collapse_caret_csv_filtering").addClass("fas fa-caret-down");
            }
        });

    //check state of collapsed form in cms/artworks
    checkCollapsedForm();

    //stop close dropdown if we click inside:
    $(".custom-dropdown").on("click", function(e) {
        e.stopPropagation();
    });

    //Activate Search on Enter:
    $("#s_artwork_sku, #s_artwork_artist, #s_artwork_title").keypress(function(
        e
    ) {
        if (e.keyCode === 13) {
            e.preventDefault();
            $("#artworks_search_form").submit();
        }
    });

    //Render saved tags:
    renderHiddenTags();

    //Add listener to dropdown->checkbox for create/delete tags:
    $(".dropdown_check_item").change(function() {
        let type = $(this)
            .closest("ul")
            .attr("id");
        let id = this.value;
        let text = $(this)
            .closest("label")
            .text();

        if (this.checked) {
            if(type != "checkbox_art_styles_csv" && type != "checkbox_brands_csv") {
                addSearchTagArtwork(type, id, text);
            }
        } else {
            deleteTagsFromHidden(type, id);
            $(".artworks_search_tags_on")
                .find(`#${type}-${id}`)
                .closest("div")
                .remove();
        }
    });

    //Reset Search Form Button
    $("#artworks_reset_search").click(function() {
        /* $('#hidden_searchbox_collapsed').val('non-collapsed') */
        $("#s_artwork_sku").val("");
        $("#s_artwork_artist").val("");
        $("#s_artwork_title").val("");

        $("#filter_artworks_tags_hidden").val("");

        $('input[name="filter_artwork_mood[]"]').each(function() {
            this.checked = false;
        });
        $('input[name="filter_artwork_art_style[]"]').each(function() {
            this.checked = false;
        });
        $('input[name="filter_artwork_theme[]"]').each(function() {
            this.checked = false;
        });
        $('input[name="filter_artwork_colour[]"]').each(function() {
            this.checked = false;
        });

        $("#artworks_search_form").submit();
    });

    /**
     * PreArtworks onload listeners:
     */

    $("body").on("click", ".remove-client-selected", function() {
        if (confirm("Are you sure you want to delete that client?")) {
            $(`#client-selected-${this.id}`).remove();
            disableAllActiveClients();
        }
    });

    //switch select type (Content Provider / Brand):
    $(".client-type__radio").change(function() {
        let active;
        let hide;

        //prevent switch to Brand if we have more than two additional Content Providers
        if (
            $(this).attr("id") === "type_brand" &&
            $(".clients_added").length > 2
        ) {
            alert("Brand just can have two additional Content Providers");
            $("#type_content_provider").prop("checked", true);
            return;
        }

        if (this.value === "Content Provider") {
            active = "content_provider";
            hide = "brand";
            $("#info_content_provider").text(
                "10 Content Providers max allowed*"
            );
        } else if (this.value === "Brand") {
            active = "brand";
            hide = "content_provider";
            $("#info_content_provider").text(
                "2 Content Providers max allowed*"
            );
        }

        $("#owner_content_provider, #owner_brand").removeClass("d-none");
        $(`#owner_${hide}`).addClass("d-none");
        $(`#owner_${hide}-select`).prop("disabled", true);
        $(`#owner_${hide}-select option`).prop("disabled", false);
        $(`#owner_${hide}-select option[value="default"]`).attr(
            "selected",
            true
        );
        $("#owner_artists").empty();
        $(`#owner_${active}-select`).prop("disabled", false);
        disableAllActiveClients();
    });

    //add new partner to client:
    $("#add_client_button").on("click", function() {
        const clientSelectedName = $("#client_partner option:selected").text();
        const clientSelectedId = $("#client_partner").val();

        //prevent action if client option is disabled
        if ($("#client_partner option:selected").prop("disabled") === true) {
            return;
        }
        addClientSelected(clientSelectedName, clientSelectedId);
    });

    //owner select listener:
    $(".client__owner-select").change(function() {
        const clientId = this.value;
        disableAllActiveClients();

        if (clientId === "default") {
            //empty artists select:
            $("#owner_artists").empty();
            $("#owner_artists").prop("disabled", true);
            return;
        } else {
            getArtists(clientId);
            //enable owner percent:
            $("#owner_client_percent").prop("disabled", false);

            if ($(this).find('option:selected').text() === 'Artlume') {
                $("#owner_client_percent").val(100);
            } else {
                $("#owner_client_percent").val(20);
            }

            //enable add client options:
            $("#add_client_button").prop("disabled", false);
            $("#client_partner").prop("disabled", false);
        }
    });

    $('#navbarSupportedContent').hide();

    $('.navbar-toggler').click(function() {
        $('#navbarSupportedContent').toggle();
    });

    $('#csv_filtering').hide();
    $('#btn_collapse_csv_filtering').click(function() {
        $('#csv_filtering').toggle();
    });
    $('#artworks_search').hide();
    $('#btn_collapse_searchbox').click(function() {
        $('#artworks_search').toggle();
    });

});

/**
 *
 * Cms PreArtworks
 *
 */

function getArtists(clientId) {
    let formData = new FormData();
    formData.append("client", clientId);
    window
        .ajaxPostCall("clients/artists", formData)
        .then(response => {
            fillOwnerArtists(response.data);
        })
        .catch(() => {
            // console.log(e);
        });
}

toggleCoupon = event => {
    let element = event.target;
    return window
        .ajaxGetCall(
            APP_URL + $(element).attr("data-url") + $(element).attr("data-id")
        )
        .then(() => {
            window.location.href = APP_URL + "/cms/coupons";
        });
};

function addClientSelected(clientName, clientId) {
    if (rulesBeforeAddClient(clientName, clientId)) {
        return;
    }

    const html = `
            <div class="form-group client-selected-item row mb-4" id="client-selected-${clientId}">
                <div class="input-group col-sm-9">
                    <div class="input-group-prepend">
                        <label class="input-group-text" for="client-added-item-${clientId}">Content Provider</label>
                    </div>
                    <input type="text" class="form-control" value="${clientName}" id="client-added-item-${clientId}" readonly>
                    <input type="hidden" name="clients[]" class="clients_added" value="${clientId}">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fas fa-times remove-client-selected" id="${clientId}"></i>
                        </span>
                    </div>
                </div>
                <div class="input-group col-sm-3">
                    <input type="number" min="0" max="100" class="form-control text-center clients-percent" name="clients_percent[]" value="0">
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <strong>%</strong>
                        </span>
                    </div>
                </div>
            </div>
        `;

    //add selected client:
    $(`#client-partner__add`).append(html);

    disableAllActiveClients();
}

function rulesBeforeAddClient(clientName, clientId) {
    //prevent add if default option or client added yet is selected:
    if ($("#client_partner option:selected").val() === "default") {
        return true;
    } else if (
        $(`#client_partner option[value=${clientId}]`).prop("disabled") === true
    ) {
        return true;
    }

    //limit Brand to two additional Content Providers:
    if ($(".clients_added").length >= 2) {
        if ($("#type_brand").is(":checked")) {
            alert("Brand just can have two additional Content Providers");
            return true;
        }
    }
}

function fillOwnerArtists(artists) {
    if (artists.length) {
        artists.forEach(artist =>
            $("#owner_artists").append(
                `<option value="${artist.id}">${artist.name}</option>`
            )
        );
        $("#owner_artists").prop("disabled", false);
    } else {
        $("#owner_artists").empty();
        $("#owner_artists").prop("disabled", true);
    }
}

function disableAllActiveClients() {
    const addedClients = [];

    //get owner client selected:
    let ownerSelected = $(".client__owner-select:enabled").val();

    if (ownerSelected !== "default") {
        addedClients.push(ownerSelected);
    }

    //get added clients:
    $(".clients_added").each((index, elem) => {
        let value = $(elem).val();

        if (value !== "default") {
            addedClients.push(value);
        }
    });

    //enable all options:
    $(".client__owner-select:enabled option, #client_partner option").prop(
        "disabled",
        false
    );

    //disable owner options already added
    $(".client__owner-select:enabled option:not(:selected)").each(
        (index, elem) => {
            if (addedClients.includes($(elem).val())) {
                $(elem).prop("disabled", true);
            }
        }
    );

    //disable partner options already added
    $("#client_partner option").each((index, elem) => {
        if (addedClients.includes($(elem).val())) {
            $(elem).prop("disabled", true);
        }
    });
}

/*
 *
 * Cms Artworks
 *
 */
exportArtworkCsv = async function() {
    // const data = new URLSearchParams();
    const data = new FormData();
    data.append('filter_artwork_artist', $("#s_artwork_artist_csv").val());
    data.append('filter_artwork_from', $("#i_from").val());
    data.append('filter_artwork_to', $("#i_to").val());

    // Add dropdown multiselect filtering (first value of array is id, second is name of input)
    let checkboxes = [
        ['checkbox_art_styles_csv', 'filter_artwork_mood'],
        ['checkbox_brands_csv', 'filter_artwork_brand']
    ]
    checkboxes.forEach(checkbox => {
        let checkboxValues = $(`#${checkbox[0]} li input:checked`).map(function(){return $(this).val();}).get();
        checkboxValues.forEach((mood, i) => data.append(`${checkbox[1]}[${i}]`, mood))
    })

    fetch('/cms/artworks/export-csv', {
        method: 'POST',
        body: data
    })
    .then( res => res.blob() )
    .then( blob => {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
    });
}

function showUploadArtworkForm(e) {
    if (e.target.value === "csvfile") {
        $("#upload-artworks-form").addClass("d-none");
        $("#upload-csv-form").removeClass("d-none");
    } else {
        $("#upload-artworks-form").removeClass("d-none");
        $("#upload-csv-form").addClass("d-none");
    }
}

function addSearchTagArtwork(type, id, text) {
    let text_type;

    switch (type) {
        case "checkbox_art_styles":
            text_type = "Art Style";
            break;
        case "checkbox_moods":
            text_type = "Mood";
            break;
        case "checkbox_themes":
            text_type = "Theme";
            break;
        case "checkbox_colours":
            text_type = "Colour";
            break;
        case "checkbox_brands":
            text_type = "Brands";
            break;
        case "checkbox_playlists":
            text_type = "Playlists";
            break;
    }

    const tag_html = `
            <div class="artworks_search_tags_on mb-2" id="${type}-${id}">
                <span><strong>${text_type}</strong>: ${text}</span>
                <i class="fas fa-times close_search_checked" id="${type}-${id}"></i>
            </div>
        `;

    $("#artworks_search_tags").append(tag_html);

    saveTagsOnHidden(type, id, text);

    //add close listener to tag
    $(".close_search_checked").click(function() {
        let split = this.id.split("-");
        let type = split[0]; //class of checkbox
        let id = split[1]; //id of checkbox

        $(`#${type} li input[value=${id}]`).prop("checked", false); //dropdown checkbox input
        $(this)
            .closest("div")
            .remove(); //delete this tag
        deleteTagFromHidden(text);
    });
}

function deleteTagsFromHidden(type, id) {
    const hiddenTagsInput = $("#filter_artworks_tags_hidden");
    let hiddenTagsArray = hiddenTagsInput.val()
        ? JSON.parse(hiddenTagsInput.val())
        : [];
    hiddenTagsArray = hiddenTagsArray.filter(item => {
        return item.id !== id && item.type === type;
    });
    hiddenTagsInput.val(JSON.stringify(hiddenTagsArray));
}

function saveTagsOnHidden(type, id, text) {
    const hiddenTagsInput = $("#filter_artworks_tags_hidden");
    let hiddenTagsArray = hiddenTagsInput.val()
        ? JSON.parse(hiddenTagsInput.val())
        : [];
    let tag = {
        id: id,
        type: type,
        text: text
    };
    hiddenTagsArray.push(tag);
    hiddenTagsInput.val(JSON.stringify(hiddenTagsArray));
}

function deleteTagFromHidden(text) {
    const hiddenTagsInput = $("#filter_artworks_tags_hidden");

    let hiddenTagsArray = JSON.parse(hiddenTagsInput.val());
    let newHiddenTagsArray = hiddenTagsArray.filter(
        object => object.text !== text
    );

    hiddenTagsInput.val(JSON.stringify(newHiddenTagsArray));
}

function renderHiddenTags() {
    const hiddenTagsInput = $("#filter_artworks_tags_hidden").val();
    $("#filter_artworks_tags_hidden").val("");

    //if hidden has tags, render all tags:
    if (hiddenTagsInput) {
        JSON.parse(hiddenTagsInput).forEach(tag => {
            addSearchTagArtwork(tag.type, tag.id, tag.text);
        });
    }
}

function checkCollapsedForm() {
    if ($("#hidden_searchbox_collapsed").val() === "collapsed") {
        $("#artworks_search").addClass("show");
        $("#collapse_caret").removeClass("fa-caret-down");
        $("#collapse_caret").addClass("fa-caret-up");
    } else {
        $("#artworks_search").removeClass("show");
    }
}

/**
 * Cms/Playlists
 *
 */

function searchArtworks() {
    let s = $("#s").val();
    if (s !== "" && typeof s === "string") {
        window.location.href = APP_URL + "/cms/s?s=" + s;
    } else {
        $(".search-box").addClass("has-error");
    }
}

function togglePreferenceCheckbox() {
    if (
        $(this)
            .find(".form-check-input")
            .is(":checked")
    ) {
        $(this).addClass("checked");
    } else {
        $(this).removeClass("checked");
    }
}

function checkIsChecked() {
    $(".radio-img-box").removeClass("checked");
    let id = $(this).attr("data-id");
    if ($(this).is(":checked")) {
        $("#" + id).addClass("checked");
    }
}

async function uploadArtworks() {

    $(this).html('<i class="fas fa-spin fa-spinner"></i>').attr("disabled", true);

    if (Object.keys(artworks).length > 0) {
        const artwork = Object.values(artworks)[0];
        delete artworks[Object.keys(artworks)[0]];

        const formData = new FormData();

        formData.append("artwork", artwork, artwork.name);

        window.ajaxPostCall($("#upload-artworks-form").attr("action"), formData)
            .then(() => {
                $("#unit-" + Object.keys(artworks)[0]).addClass("uploaded");
                uploadArtworks();
            });
    } else {
        $("#preview-file-zone").html("");
        $("#upload-artworks-btn").removeAttr("disabled").html("Upload").addClass("d-none");
    }
}

function fileExplorer() {
    document.getElementById("artworks").click();
    document.getElementById("artworks").onchange = () => {
        let files = document.getElementById("artworks").files;
        if (files) {
            readImageFiles(files);
        }
    };
}

function readImageFiles(files) {
    $(".upload-file-error")
        .addClass("d-none")
        .html("");
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let id = file.name.toLowerCase().replace(/[^\w\s!?]/g, "").replaceAll(' ','');
        if (!artworks[id]) {
            artworks[id] = file;
            $("#preview-file-zone").append(
                '<div id="unit-' +
                    id +
                    '" class="unit-img">' +
                    '<span id="' +
                    id +
                    '">X</span>' +
                    '<img id="image-preview-' +
                    id +
                    '" src="" alt="">' +
                    "</div>"
            );
            let reader = new FileReader();
            reader.onload = function(e) {
                let img = new Image();
                img.onload = function() {
                    if (img.width !== 3840 || img.height !== 2160) {
                        delete artworks[id];
                        $("#unit-" + id).remove();
                        $(".upload-file-error")
                            .removeClass("d-none")
                            .html(
                                "One of the images is not a 4k (3840x2160) image."
                            );
                        toggleUploadButton();
                    } else {
                        $("#image-preview-" + id).attr("src", e.target.result);
                    }
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }
    toggleUploadButton();
    $(".unit-img span").on("click", deleteImageUpload);
}

function toggleUploadButton() {
    if (Object.keys(artworks).length > 0) {
        $("#upload-artworks-btn").removeClass("d-none");
    } else {
        $("#upload-artworks-btn").addClass("d-none");
    }
}

function deleteImageUpload() {
    let id = $(this).attr("id");
    if (artworks[id]) {
        $("#unit-" + id).remove();
        delete artworks[id];
    }
    toggleUploadButton();
}

function getClientArtists() {
    let client = $(this).val();
    if (client !== "" && client !== null) {
        let formData = new FormData();
        formData.append("client", client);
        window
            .ajaxPostCall(APP_URL + "/cms/clients/artists", formData)
            .then(res => {
                if (res && res.response === "Ok") {
                    $("#artist").removeAttr("disabled");
                    let artists = res.data;
                    $("#artist").html("<option></option>");
                    for (let i = 0; i < artists.length; i++) {
                        const artist = artists[i];
                        $("#artist").append(
                            '<option value="' +
                                artist.id +
                                '">' +
                                artist.name +
                                "</option>"
                        );
                    }
                } else {
                    //console.error(res);
                }
            });
    }
}

function removePlaylistArtwork() {
    if (confirm("Are you sure?")) {
        let artwork = $(this).attr("data-artwork");
        let playlist = $(this).attr("data-playlist");
        window
            .ajaxGetCall(
                APP_URL +
                    "/cms/playlists/remove-artwork/" +
                    artwork +
                    "/" +
                    playlist
            )
            .then(res => {
                if (res.message === "Error") {
                    //console.error(res.data);
                } else {
                    $("#artwork-" + artwork).remove();
                }
            });
    }
}

function deletePreArtworks() {
    if (confirm("Are you sure?")) {
        let _artworks = $("#artworks").val();
        let btn = $(this);
        btn.html('<i class="fas fa-spin fa-spinner"></i>');
        if (_artworks !== "") {
            let formData = new FormData();
            formData.append("artworks", _artworks);
            window
                .ajaxPostCall(
                    APP_URL + "/cms/artworks/delete-pre-artwork-bulk",
                    formData
                )
                .then(() => {
                    window.location.reload();
                });
        }
    }
}

function deleteArtworks() {
    if (confirm("Are you sure?")) {
        let _artworks = $("#artworks").val();
        let btn = $(this);
        btn.html('<i class="fas fa-spin fa-spinner"></i>');
        if (_artworks !== "") {
            let formData = new FormData();
            formData.append("artworks", _artworks);
            window
                .ajaxPostCall(APP_URL + "/cms/artworks/delete-bulk", formData)
                .then(() => {
                    window.location.reload();
                });
        }
    }
}

function deleteUsers() {
    if (confirm("Are you sure?")) {
        let _users = $("#users").val();
        let btn = $(this);
        btn.html('<i class="fas fa-spin fa-spinner"></i>');
        if (_users !== "") {
            let formData = new FormData();
            formData.append("users", _users);
            window
                .ajaxPostCall(APP_URL + "/cms/users/delete-bulk", formData)
                .then(() => {
                    window.location.reload();
                });
        }
    }
}

function toggleGridCheckboxes() {
    $(".grid .grid-image-wrapper").each(() => {
        let isChecked = $(".checkbox-grid-main").is(":checked") ? true : false;
        if (isChecked) {
            $(this).addClass("active");
        } else {
            $(this).removeClass("active");
        }
    });
    checkGridCheckboxes();
}

function toggleGridCheckbox() {
    let parent = $(this).parent();
    if (parent.hasClass("active")) {
        parent.removeClass("active");
    } else {
        parent.addClass("active");
    }
    checkGridCheckboxes();
}

function checkGridCheckboxes() {
    let _artworks = [];
    $(".grid .grid-image-wrapper").each(() => {
        if ($(this).hasClass("active")) {
            _artworks.push($(this).attr("data-id"));
        }
    });
    if (_artworks.length > 0) {
        $(".btn-modify").prop("disabled", false);
    } else {
        $(".btn-modify").prop("disabled", true);
    }
    if ($("#playlist-artworks").length) {
        $("#playlist-artworks").val(_artworks.join(","));
    }
    $("#artworks").val(_artworks.join(","));
}

function redirectToStats() {
    window.location.href =
        APP_URL +
        "/cms/content-providers/" +
        $("#client").val() +
        "/stats?month=" +
        $("#month").val() +
        "&year=" +
        $("#year").val();
}

function redirectToBrandStats() {
    window.location.href =
        APP_URL +
        "/cms/brands/" +
        $("#client").val() +
        "/stats?month=" +
        $("#month").val() +
        "&year=" +
        $("#year").val();
}

function setClipboard() {
    let value = $(this).attr("data-value");
    $(".span-copy").html("Copy URL!");
    $(this)
        .parent()
        .find(".span-copy")
        .html("Copied!");
    var tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
}

function toggleCheckboxes() {
    $("table td .checkbox-list").each((index, element) => {
        let isChecked = $(".checkbox-main").is(":checked") ? true : false;
        if (isChecked) {
            $(element).attr("checked", true);
        } else {
            $(element).removeAttr("checked");
        }
    });
    checkCheckboxes();
}

function checkCheckboxes() {
    let type = $(".checkbox-main").attr("data-type");
    let items = [];
    $("table td .checkbox-list").each((index, element) => {
        if ($(element).is(":checked")) {
            items.push($(element).val());
        }
    });
    if (items.length > 0) {
        $(".btn-modify").prop("disabled", false);
    } else {
        $(".btn-modify").prop("disabled", true);
    }
    if (type === "artwork") {
        if ($("#playlist-artworks").length) {
            $("#playlist-artworks").val(items.join(","));
        }
        $("#artworks").val(items.join(","));
    } else if (type === "playlist") {
        $("#playlists").val(items.join(","));
        $("#playlists-to-edit").val(items.join(","));
    }else if (type === "user") {
        $("#users").val(items.join(","));
    }
}

// Reads the image and show it
function readImg() {
    let type = $(this).attr("id");
    let input = document.getElementById(type);
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $("#" + type + "-preview").attr("src", e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
    } else {
        $("#" + type + "-preview").attr("src", "");
    }
}

// Validates the artwork modal form before submit
function validateUpdateArtworkForm() {
    //check total Percent:
    sumPercent = 0;

    $("#client__form-errors").empty();
    $(".clients-percent").each(function() {
        sumPercent += Number($(this).val());
    });
    if (sumPercent > 100) {
        const message = `
                <div class="col-6 offset-3">
                    <p class="text-danger text-center">Total percent must be 100%</p>
                </div>
            `;
        $("#client__form-errors").append(message);
        return;
    }

    //check Artist:
    if ($("#owner_artists").val() === "default") {
        const message = `
                <div class="col-12">
                    <p class="text-danger text-left"><small>Artist field is required</small></p>
                </div>
            `;
        $("#client__artists").append(message);
        return;
    }

    if ($("#update-artwork-form").valid()) {
        window
            .ajaxPostCall(
                $("#update-artwork-form").attr("action"),
                new FormData($("#update-artwork-form")[0])
            )
            .then(res => {
                if (res.message && res.message !== "Error") {
                    /*  console.log(res) */
                } else {
                    /* console.log(res) */
                    window.location.reload();
                }
            });
    }
}

// Validates the artwork modal form before submit
function validatePlaylistForm() {
    $("#add-playlist-form").validate();
    if ($("#add-playlist-form").valid()) {
        window
            .ajaxPostCall(
                $("#add-playlist-form").attr("action"),
                new FormData($("#add-playlist-form")[0])
            )
            .then(res => {
                if (res.message && res.message === "Error") {
                    //console.error(res.data);
                } else {
                    window.location.reload();
                }
            });
    }
}

// Reads the file name and show it
function readFileName(e) {
    let id = $(e.target).attr("data-target");
    if (e.target.files && e.target.files[0]) {
        $(id)
            .addClass("mb-3")
            .html(e.target.files[0].name);
    } else {
        $(id)
            .removeClass("mb-3")
            .html("");
    }
}

// Validates the artwork form before submit
function validateForm() {
    $("#publish-form").validate();
    let validFiles = $("#image").attr("required") ? false : true;
    $('input[name^="image"][data-max-size]').each(() => {
        if ($(this).attr("required")) {
            if (typeof this.files[0] !== "undefined") {
                var maxSize = parseInt($(this).attr("data-max-size"), 10);
                if (maxSize < this.files[0].size) {
                    document.getElementById(
                        this.id + "-warning"
                    ).style.display = "block";
                    event.preventDefault();
                    event.stopPropagation();
                    validFiles = false;
                } else {
                    validFiles = true;
                    document.getElementById(
                        this.id + "-warning"
                    ).style.display = "none";
                }
            } else {
                document.getElementById(this.id + "-warning").style.display =
                    "block";
            }
        }
    });

    let type = $("#type").val();
    let videoArt = $("#video_arts").val();
    if (videoArt === "" && type === "Dynamic Art") {
        validFiles = false;
        $("#video_arts")
            .parent()
            .addClass("has-error");
    } else {
        $("#video_arts")
            .parent()
            .removeClass("has-error");
    }

    if ($("#publish-form").valid() && validFiles) {
        $("#publish-form").submit();
    }
}

function toggleVideoArts() {
    let value = $(this).val();
    if (value === "Dynamic Art") {
        $("#videoarts").removeClass("d-none");
    } else {
        $("#videoarts").addClass("d-none");
    }
}

function copyPlaylistsToCustom() {
    if (confirm("Are you sure?")) {
        $("#playlist-form").validate();
        let btn = $(this);
        const moveAction = btn.attr('move-action');
        btn.html('<i class="fas fa-spin fa-spinner"></i>');
        if ($("#playlist-form").valid()) {
            let $url = $(`input[move-action="${moveAction}"]`).val();
            window
                .ajaxPostCall(
                    // $("#playlist-custom-action").find(`[move-action='${moveAction}']`).val(),
                    $url,
                    new FormData($("#playlist-form")[0])
                )
                .then(res => {
                    if (res.message && res.message === "Error") {
                        btn.html('<i class="fas fa-trash"></i>');
                    } else {
                        window.location.href = res.data;
                    }
                });
        }
    }
}

function deletePlaylists() {
    if (confirm("Are you sure?")) {
        $("#playlist-form").validate();
        let btn = $(this);
        btn.html('<i class="fas fa-spin fa-spinner"></i>');
        if ($("#playlist-form").valid()) {
            window
                .ajaxPostCall(
                    $("#playlist-form").attr("action"),
                    new FormData($("#playlist-form")[0])
                )
                .then(res => {
                    if (res.message && res.message === "Error") {
                        btn.html('<i class="fas fa-trash"></i>');
                    } else {
                        window.location.reload();
                    }
                });
        }
    }
}

function validateUpdatePlaylistForm() {
    $("#update-playlist-form").validate();
    if ($("#update-playlist-form").valid()) {
        window
            .ajaxPostCall(
                $("#update-playlist-form").attr("action"),
                new FormData($("#update-playlist-form")[0])
            )
            .then(res => {
                if (res.message && res.message === "Error") {
                    //console.error(res.data);
                } else {
                    window.location.reload();
                }
            });
    }
}

function deleteImagePlaylistForm() {
    document.getElementById("image-preview").src = "";
    document.getElementById("image").value = "";
}

// Set date range using quick select button such as last week, month, year
window.setDateRange = (fromInputId, toInputId, timeDifference) => {
    const currentDate = new Date();
    const pastDate = new Date(currentDate);
    pastDate.setDate(currentDate.getDate() - timeDifference);

    const formatDate = date => date.toLocaleDateString('en-GB').replace(/\//g, '-');

    $(`#${fromInputId}`).val(formatDate(pastDate));
    $(`#${toInputId}`).val(formatDate(currentDate));
};


exportUsersCsv = async function() {
    const data = new FormData();
    data.append('filter_user_from_joined', $("#i_from_joined").val());
    data.append('filter_user_to_joined', $("#i_to_joined").val());
    data.append('filter_user_from_expiring', $("#i_from_expiring").val());
    data.append('filter_user_to_expiring', $("#i_to_expiring").val());
    data.append('filter_user_subscription_type', $("#i_subscription_type").val());
    data.append('filter_user_country', $("#i_country").val());

    fetch('/cms/users/export-csv', {
        method: 'POST',
        body: data
    })
    .then( res => res.blob() )
    .then( blob => {
        var file = window.URL.createObjectURL(blob);
        window.location.assign(file);
    });
}

// Set date range using quick select button such as last week, month, year
setDateRange = function(fromInputId, toInputId, timeDifference) {
    const date = new Date();
    $(`#${fromInputId}`).val(new Date(date.setDate(date.getDate() - timeDifference)).toLocaleDateString('en-GB').replace(/\//g, '-'));
    $(`#${toInputId}`).val(new Date().toLocaleDateString('en-GB').replace(/\//g, '-'));
}
